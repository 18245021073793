<template>
    <router-view />
</template>

<script>
export default {
    name: 'App',
    data: () => ({
        userId: JSON.parse(localStorage.getItem('userId')),
    }),
}
</script>
