import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, { directives: { Ripple } })

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                biddingTag: '#e0ecff',
                biddingText: '#1976d2',
                approvalTag: '#fdecd6',
                approvalText: '#eb9723',
                followUpTag: '#fef5d8',
                followUpText: '#efbd21',
                lostTag: '#fbe0e0',
                lostText: '#db4d42',
                awardedTag: '#d3f3e6',
                awardedText: '#23bf85',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                light_gray: '#c1c1c1',
                deliverRow: '#fff7d6',
                alertRow: '#F9D6BC',
                closingRow: '#808B96',
            },
        },
    },
})
