import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";

import Vue from "vue";

Vue.use(Viewer);
Viewer.setDefaults({
  title: false,
  toolbar: {
    zoomIn: { show: 0 },
    zoomOut: { show: 0 },
    oneToOne: { show: 0 },
    reset: { show: 0 },
    prev: 4,
    play: {
      show: 4,
      size: "large"
    },
    next: 4,
    rotateLeft: { show: 0 },
    rotateRight: { show: 0 },
    flipHorizontal: { show: false },
    flipVertical: { show: false }
  },
  url: (image) => {
    return image.alt;
  },
  transition: false, 
});
export default Viewer;
