import firebase from 'firebase'
import 'firebase/analytics'
require('firebase/auth')
require('firebase/firestore')
require('firebase/storage')
require('firebase/messaging')

const config = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

firebase.initializeApp(config)

export const auth = firebase.auth
firebase
    .firestore()
    .enablePersistence()
    .catch(err => {
        if (err.code == 'failed-precondition') {
            console.log(
                'Multiple tabs open, persistence can only be enabled in one tab at a a time.'
            )
        } else if (err.code == 'unimplemented') {
            console.log(
                'The current browser does not support all of the features required to enable persistence'
            )
        }
    })
export const firestore = firebase.firestore()
export const storage = firebase.storage
export const timestamp = firebase.firestore.Timestamp
export const analytics = firebase.analytics()
// notifications
export const messaging = firebase.messaging.isSupported()
    ? firebase.messaging()
    : undefined

export const vapidKey = process.env.VUE_APP_VAPIDKEY
//
export default firebase
